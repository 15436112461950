@use '@styles/base.scss';

.root {
  width: 100%;
  max-width: 1070px;
  margin: auto;

  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;

  &__right {
    flex-direction: row-reverse;
  }

  @media (max-width: base.$breakpoint-md) {
    flex-direction: column;
  }

  &__area,
  &__content {
    border-radius: 30px;
  }

  &__area {
    flex: 1;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }

    @media (max-width: base.$breakpoint-md) {
      flex: unset;
      height: 350px;
    }
  }

  &__content {
    flex: 1.5;
    min-height: 450px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;

    padding: 40px;
    border: 1px solid base.$grey-extra-light;

    @media (max-width: base.$breakpoint-md) {
      min-height: unset;
      padding: 32px 20px;
    }

    &_title {
      font-size: 25px;
      text-align: left;
      text-transform: uppercase;
      font-family: 'Druk', sans-serif;

      @media (max-width: base.$breakpoint-sm-big) {
        font-size: 22px;
      }
    }

    &_description {
      margin-top: 30px;

      font-size: 16px;
      text-align: left;
      color: base.$grey;
    }

    &_btn {
      padding: 8px 12px;
      border-radius: 24px;
      border: 1px solid base.$black;

      font-size: 17px;
      font-weight: 700;
      color: base.$black;
      text-transform: uppercase;

      transition: 0.3s ease-in-out;
      background-color: base.$white;

      &:hover {
        color: base.$white;
        background-color: base.$black;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
