@use '@styles/base.scss';

.root {
  height: 20px;
  margin: 15px 0;

  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: base.$breakpoint-md) {
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__img {
    width: 20px;
    height: 20px;
  }

  &__type {
    min-width: fit-content;
    font-size: 12px;
    font-weight: 700;
  }

  &__feature {
    font-size: 12px;
    color: base.$grey;
  }
}
