@use '@styles/base.scss';

.root {
  margin-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  list-style: none;

  &__item {
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;

      width: 0;
      height: 3px;
      transition: .3s ease-in-out;
      background-color: base.$black;
    }

    &__active {
      pointer-events: none;

      &::after {
        width: 100%;
      }
    }
  }
}
