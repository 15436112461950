@use '@styles/base.scss';

.root {
  width: fit-content;
  min-width: 555px;

  &__heading {
    height: 35px;
    border-bottom: 1px solid base.$grey-light-extra;
  }

  &__text {
    font-size: 12px;
    font-weight: 700;

    display: inline-block;

    padding: 2px 18px;
    border-radius: 18px;
    border: 1px solid base.$black;
  }

  &__list {
    list-style: none;

    &_item {
      margin: 15px 0;

      font-size: 15px;
      font-weight: 500;
    }
  }
}

